import { Component, OnInit } from '@angular/core';
import { Menu } from 'src/app/model/menu';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  menuList:any[];

  constructor(private menuService:MenuService) { } 

  ngOnInit(): void {
    this.menuService.fetchMenu().subscribe(res=>{
      this.menuList = res;
    }) 
  }

  onMenuClick(menu: Menu, isFolder: boolean = false) {
    if (isFolder && !menu.parentId) {
      this.menuList.forEach((v) => {
        if (menu.id != v.id) {
          v.isActive = false;
        }
      });
      menu.isActive = !menu.isActive;
    } else {
      if (!isFolder) {
        this.resetMenu(this.menuList);
      }
      menu.isActive = isFolder ? !menu.isActive : true;
      if (menu.parentId) {
        this.menuList.forEach((v) => {
          this.setParentOpened(v, menu.parentId);
        });
      }
    }
  }

  resetMenu(lstMenu: Array<Menu>) {
    lstMenu.forEach((v) => {
      if (v.subMenu) {
        this.resetMenu(v.subMenu);
      }
      v.isActive = false;
    });
  }

  setParentOpened(menu: Menu, parentId: string) {
    if (menu.id == parentId) {
      menu.isActive = true;
      if (menu.parentId) {
        this.menuList.forEach((v) => {
          this.setParentOpened(v, menu.parentId);
        });
      }
    } else if (menu.subMenu) {
      menu.subMenu.forEach((v) => {
        if (v.subMenu) {
          this.setParentOpened(v, parentId);
        }
      });
    }
  }

}
