import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/model/menu';
import { PersonService } from 'src/app/services/person.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.css']
})
export class HeaderMenuComponent implements OnInit {

  isLogin:boolean = localStorage.getItem(AppSettings.authorize)? true:false;
  buyerName:string;
  constructor(private vehicleService:VehicleService,private personService:PersonService) { }

  ngOnInit(): void {
    this.vehicleService.getBuyerDetails().subscribe((res:any)=>{
      this.buyerName =   res[0].ownerName
    })
  }

  logout(){
    this.personService.onLogOut();
  }

}
