import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../model/menu';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  vehicleHistory:any[]=[];

  constructor(private http:HttpClient) { }
   
  getAllVehicleHistory(){
   return  this.http.get(AppSettings.baseURL+ "/vehicle/getAllpetrolHistory");
  }

  getAllPriceHistory(){
    return  this.http.get(AppSettings.baseURL+ "/vehicle/price");
   }

  getBuyerDetails(){
    return  this.http.get(AppSettings.baseURL+ "/buyer");
  }

  getCachedAllVehicleHistory(){
    if(this.vehicleHistory && this.vehicleHistory.length > 0){
      return of(this.vehicleHistory);
    }
    return this.getAllVehicleHistory();
  }

  getDashboard(){
    return  this.http.get(AppSettings.baseURL+ "/vehicle/getBuyerDashboard");
  }

  getVehicleDashboard(){
    return  this.http.get(AppSettings.baseURL+ "/vehicle/getBuyerDashboard");
  }

  getAllInvoices(){
    return  this.http.get(AppSettings.baseURL+ "/invoice");
  }

  download(html:any){
    return  this.http.post(AppSettings.baseURL+ "/reports/html-pdf", html, {
      responseType: 'blob',
    }); 
  }

  style:string = `.table-wrapper {
    max-width: 100%;
    overflow: auto;
  }
  .table-wrapper .table {
    display: table;
    width: 100%;
  }
  .table-wrapper .table .row {
    display: table-row;
    width: 100%;
  }
  .table-wrapper .table .row.header-row > * {
    font-weight: bold;
    color: #fff;
    background: #72be42;
    border-bottom: solid 1px #b7b7b7;
  }
  .table-wrapper .table .row > * {
    display: table-cell;
    vertical-align: middle;
    padding: 10px 20px;
    border-bottom: solid 1px #ddd;
  }
  .table-wrapper .table .row:not(.header-row):nth-child(odd) > * {
    background: #efefef;
  }
  .table-wrapper .table .row:last-child > * {
    border-bottom: 0;
  }
  .table-wrapper .table .row > *.slno {
    width: 60px;
  }
  .table-wrapper .table .row > *.button-holder {
    width: 80px;
    text-align: right;
    white-space: nowrap;
  }
  .table-wrapper .table .row > *.status-td {
    width: 110px;
  }
  .table-wrapper .table .row > *.tques-td {
    width: 110px;
  }
  .table-wrapper .table .row > *.q-no {
    width: 135px;
    text-align: center;
  }
  .table-wrapper .table .row.header-row > * a {
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;
  }
  .table-wrapper .table .row.header-row > * span {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .table-wrapper .table .row.header-row > * span.sort-arrows {
    width: 5px;
    height: 12px;
    margin-left: 8px;
  }
  .table-wrapper .table .row.header-row > * span.sort-arrows::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid#123f69;
    margin-bottom: 3px;
  }
  .table-wrapper .table .row.header-row > * span.sort-arrows::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #123f69;
  }
  .table-wrapper .table .row.header-row > * a.desc span.sort-arrows::before {
    border-bottom: 5px solid#fff;
  }
  .table-wrapper .table .row.header-row > * a.asc span.sort-arrows::after {
    border-top: 5px solid #fff;
  }
  .tab .box {
    width: 30%;
    margin-right: 3%;
    margin-bottom: 3%;
    background: var(--main-text-color);
    color: #ffffff;
  }`

}
