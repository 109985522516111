import { Component } from '@angular/core';
import { Person } from 'src/app/model/menu';
import { PersonService } from 'src/app/services/person.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  password: string;
  email: string;
  error: string;
  constructor(
    private personService: PersonService,
    private routerService: RouterService
  ) {}

  ngOnInit() {
  }

  onLogin() {
    let p: Person = new Person();
    p.password = this.password;
    p.email = this.email;
    this.personService.login(p);
  }
}
