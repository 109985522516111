import { Injectable } from '@angular/core';
import { AppSettings, Person, PersonDetails, SpeedexRouter } from '../model/menu';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
export class PersonService {
  get isPersonLoggedIn():boolean
  {
     return !localStorage.getItem(AppSettings.authorize)?false:true;
  }
  constructor(private http:HttpClient,
    private routerService:RouterService
  ) { }

  onLogOut(){
     localStorage.removeItem(AppSettings.authorize);
     var anchor = document.createElement('a');
     let url = window.location.protocol + '//' + window.location.host;
     //todo: find isdefault
     anchor.href = url + "/login";
     anchor.click();
  }

  login(person:Person){
    this.http
    .post(AppSettings.baseURL + '/login', person)
    .pipe(map((v) => <PersonDetails>v))
    .subscribe((res:any) => {
      //this.utilsService.loaderStart();
      if (res && res.person.token) {
        localStorage.setItem(AppSettings.authorize, res.person.token); 
        localStorage.setItem(AppSettings.menu, JSON.stringify(res.menu));//JSON.parse(jsonString) as Person; 
        localStorage.setItem(
          AppSettings.buyerID,
          window.btoa(res.id.toString())
        ); 
        let router = new SpeedexRouter();
        var anchor = document.createElement('a');
        let url = window.location.protocol + '//' + window.location.host;
        //todo: find isdefault
        anchor.href = url + "/dashboard";
        anchor.click();
        //this.utilsService.loaderStop();
        this.routerService.navigate(router);
      } else {
        // this.utilsService.loaderStop();
        //this._isInvalid.next(true);
      }
    });
  }
}
