import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { routes } from './widgets/router';
import { DashBoardModule } from './widgets/dash-board/dash-board.module';
import { VehicleModule } from './widgets/vehicle/vehicle.module';
import { CommonWidgetModule } from './common-widget/common-widget.module';
import { MenuService } from './services/menu.service';
import { AuthInterceptorService } from './init/auth-interceptor.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule, 
    CommonWidgetModule,
    RouterModule.forRoot(routes)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    MenuService],
  bootstrap: [AppComponent]
})
export class AppModule { }
