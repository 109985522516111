import { DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApexChart } from 'ng-apexcharts';
import { of, switchMap } from 'rxjs';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnChanges {
  @Input() isVehicleDashBoard:boolean;
  data:any[];
  xAxis:string[]=[];
  yAxis:number[]=[];
  yAxisDict:{[key:string]:number}={}
  chart:ApexChart;
  chartOptions:any = null;
  constructor(private vehicleService:VehicleService,
    private decimalPipe: DecimalPipe
  ){
  }
  ngOnChanges(changes: SimpleChanges) {
    this.setData() 
  }

  ngOnInit(){
    this.xAxis = [];

    this.setData() 
    
      
  } 
  setData(){
    this.vehicleService.getCachedAllVehicleHistory()
      .pipe(
        switchMap((res:any)=>{
        this.data = res;
        this.data.forEach(d=>{
          let vNumber:string=d.vehicleInfo.split('-')[1]
          if(! this.xAxis.find(v=>v == vNumber)){
            this.xAxis.push(vNumber)
          }
          if(!this.yAxisDict[vNumber]){
            this.yAxisDict[vNumber] = d.liter;
          }else{
            this.yAxisDict[vNumber] = this.yAxisDict[vNumber] + d.liter;
          }
        })
        Object.keys(this.yAxisDict).forEach(key => {
          let yAxis = Number.parseInt(this.decimalPipe.transform(this.yAxisDict[key], '1.0-2'));
          this.yAxis.push(yAxis);
        })
        return of(null)
        }))
      .subscribe((res)=>{
        this.setChart()
      })
  }
 
  setChart(){
    this.chart= {
      type: "bar"
  
    }
    this.chartOptions = {
      series: [{
      name: 'Fuel',
      data: this.yAxis
    }],
      chart: {
      type: 'bar',
      height: 700
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '75%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: this.xAxis,
    },
    yaxis: {
      title: {
        text: '$ (thousands)'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter:  (val:any)=> {
          return "$ " + this.decimalPipe.transform(val, '1.0-2')
        }
      }
    }
    };
  }

}
