import { HttpErrorResponse, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { AppSettings, CustomRouter } from '../model/menu';
import { UtilityService } from '../services/utility.service';
import { PersonService } from '../services/person.service';
import { RouterService } from '../services/router.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private routerService: RouterService,
    private person: PersonService,
    private utilsService: UtilityService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req instanceof HttpRequest) {
      this.utilsService.loaderStart();
      if (!anonymousUrls.find((v) => v === req.url)) {
        req = req.clone({
          headers: new HttpHeaders({
            Authorization:
              'Bearer ' + localStorage.getItem(AppSettings.authorize),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          }),
        });
      }
      else{
        req = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          }),
        });
      }
    }

    return next.handle(req).pipe(
      tap((v) => {
        if (v instanceof HttpResponse) {
          this.utilsService.loaderStop();
        }
      }),
      catchError((err) => {
        this.utilsService.loaderStop();
        let router = new CustomRouter();
        router.keepHistory = false;
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401) {
            this.person.onLogOut();
          } else {
            router.url = '/error';
          }
        } else {
          router.url = '/error';
        }
        this.utilsService.loaderStop();
        this.routerService.navigate(router);
        return of(err.statusText);
      })
    );
  }
}

export var anonymousUrls: Array<string> = [
  AppSettings.baseURL + '/oauth/token',
  AppSettings.baseURL + '/login',
  AppSettings.baseURL + '/login/signup',
  AppSettings.baseURL + '/error',
  AppSettings.baseURL + '/forgotPassword'
];
