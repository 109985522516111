import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { RouterModule, Routes } from '@angular/router';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { CommonLoadingComponent } from './common-loading/common-loading.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  } 
];

@NgModule({
  declarations: [
    NavMenuComponent,
    HeaderMenuComponent,
    PieChartComponent,
    BarChartComponent,
    LineChartComponent,
    LoginComponent,
    CommonLoadingComponent
  ],
  imports: [
    CommonModule,
    FormsModule, 
    NgApexchartsModule, 
    RouterModule.forChild(routes),
  ],
  providers: [
    DatePipe,
    DecimalPipe
  ],
  exports:[
    NavMenuComponent,
    HeaderMenuComponent,
    PieChartComponent,
    BarChartComponent,
    LineChartComponent,
    CommonLoadingComponent
  ]
})
export class CommonWidgetModule { }
