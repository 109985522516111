import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private count=0;
  private _loader:Subject<boolean>=new Subject();
  public $loader:Observable<boolean>=this._loader.asObservable();


  constructor() { }

  public loaderStart(){
    this.count++;
    this._loader.next(true);
  }

  public loaderStop(reset:boolean=false){
    this.count--;
    if(this.count==0 || this.count<0 || reset){
      this.count = 0;
      this._loader.next(false);
    }
  }
}
