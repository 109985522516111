import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-common-loading',
  templateUrl: './common-loading.component.html',
  styleUrls: ['./common-loading.component.scss']
})
export class CommonLoadingComponent implements OnInit { 
  load:boolean=false;

  constructor(private utility:UtilityService){

  }
  ngOnInit(): void {
    this.utility.$loader.subscribe(res=>{
      this.load = res;
    })
  }

 
}
