import { Component } from '@angular/core';
import { ApexChart, ApexOptions } from 'ng-apexcharts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent {
  
  chart:ApexChart= {
    type: "donut",
    toolbar:{
      export:{}
    }
  }
  
  chartOptions:ApexOptions = {
    series: [60, 20, 30, 10],
    chart: {
    type: 'donut',
  },
  labels: ['Good', 'Fair', 'Needs Repair', 'Critical'],
  responsive: [{
    breakpoint: 480,
    options: {
      chart: {
        width: 200
      },
      legend: {
        position: 'bottom'
      }
    }
  }]
  };


}
