<section class="inps0 login-outer">
    <div class="wrapper">
      <div class="login-inner">
        <div class="login-content">
          <h1>Admin Sign In</h1>
  
          <form action="subforms/login.php" method="post">
            <div class="form-error" *ngIf="error">
              {{ error }}
            </div>
            <div class="form-box">
              <label for="username">* Username</label>
              <input
                type="text"
                placeholder="Email"
                name="username"
                [(ngModel)]="email"
                id="username"
                required
              />
            </div>
            <div class="form-box">
              <label for="password">* Password</label>
              <input type="password" [(ngModel)]="password" name="password" id="password" required/>
            </div>
            <div class="form-box">
              <button
                class="btn"
                (click)="onLogin()"
                name="subLogin"
                type="submit"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  