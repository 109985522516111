<div class="side-menu">
  <div class="heading">
    <div class="logo">
      <a href="/"><img src="../../../assets/images/Logo.PNG" alt="" /></a>
    </div>
  </div>
  <ul>
    <ng-container *ngFor="let menu of menuList">
      <li
        [ngClass]="{ 'has-submenu': menu.subMenu, opened: menu.isActive }"
        *ngIf="menu.subMenu"
      >
        <div class="nav-link" (click)="onMenuClick(menu, true)">
          <a href="javascript:void(0)">
            <ng-container *ngIf="menu.icon">
              <i class="{{ menu.icon }}"></i>
            </ng-container>
            <span>{{ menu.labelName }}</span>
          </a>
          <div class="nav-arrow"></div>
        </div>
        <div class="submenu" *ngFor="let subMenu of menu.subMenu">
          <ng-container *ngIf="!subMenu.subMenu">
            <div class="nav-link" (click)="onMenuClick(subMenu, false)">
              <a
                [routerLink]="subMenu.routerUrl"
                [ngClass]="{ active: subMenu.isActive }"
              >
                <ng-container *ngIf="subMenu.icon">
                  <i class="{{ subMenu.icon }}"></i>
                </ng-container>
                {{ subMenu.labelName }}</a
              >
            </div>
          </ng-container>

          <ng-container *ngIf="subMenu.subMenu">
            <div
              class="nav-link"
              [ngClass]="{ opened: subMenu.isActive }"
              (click)="onMenuClick(subMenu, true)"
            >
              <a
                href="javascript:void(0)"
                [ngClass]="{ opened: subMenu.isActive }"
              >
                <ng-container *ngIf="subMenu.icon">
                  <i class="{{ subMenu.icon }}"></i>
                </ng-container>
                {{ subMenu.labelName }}</a
              >
              <div class="nav-arrow"></div>
            </div>

            <div
              class="submenu-secondary"
              [ngClass]="{ opened: subMenu.isActive }"
            >
              <ng-container *ngFor="let nav of subMenu.subMenu">
                <div class="nav-link" (click)="onMenuClick(nav, false)">
                  <a
                    [routerLink]="nav.routerUrl"
                    [ngClass]="{ active: nav.isActive }"
                  >
                    <ng-container *ngIf="nav.icon">
                      <i class="{{ nav.icon }}"></i>
                    </ng-container>
                    {{ nav.labelName }}</a
                  >
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </li>

      <li
        *ngIf="!menu.subMenu"
        (click)="onMenuClick(menu, false)"
        [ngClass]="{ active: menu.isActive }"
      >
      <div class="nav-link">
        <a [routerLink]="menu.routerUrl">
          <ng-container *ngIf="menu.icon">
            <i class="{{ menu.icon }}"></i>
          </ng-container>
          <span>{{ menu.labelName }}</span>
        </a>
      </div>
      </li>
    </ng-container>
  </ul>
</div>
