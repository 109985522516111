import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PersonService } from '../services/person.service';
import { RouterService } from '../services/router.service';
import { SpeedexRouter } from '../model/menu';
import { anonymousUrls } from '../init/auth-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class BuyerAuthGuardService  implements CanActivate {
  constructor(
    private personService: PersonService,
    private routerService: RouterService,
    private router:Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.hasRequiredPermission(route);
  }

  protected hasRequiredPermission(
    route: ActivatedRouteSnapshot
  ): Promise<boolean> | boolean {
    return new Promise<boolean>((resolve, reject) => {
      if (this.personService.isPersonLoggedIn) {
        return resolve(true);
      } else {
        if (anonymousUrls.find((v) => v === window.location.pathname)) {
          return resolve(true);
        }
        var anchor = document.createElement('a');
        let url = window.location.protocol + '//' + window.location.host;
        //todo: find isdefault
        anchor.href = url + "/login";
        anchor.click();    
        return resolve(true);
      }
    });
  }
}