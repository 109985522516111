<ng-container *ngIf="!isLogin">
  <header>
      <div class="wrapper">
          <div class="header-inner">
              <div class="logo">
                  <img src="../../../assets/images/Logo.PNG" alt="" />
              </div>
          </div>
      </div>
  </header>
</ng-container>
<ng-container *ngIf="isLogin">
<div class="main-panel">
    <div class="main-panel-header">
      <div class="admin-panel-txt"><a class="menu-bar"
          href="javascript:void(0)"><span></span><span></span><span></span></a>
          <!-- <span>Welcome</span><h2>{{buyerName}}</h2> -->
      </div>
      <div class="user-info">
<!--        <span>admin</span><p class="user-name"></p> -->
        <p><a href="javascript:void(0)" (click)="logout()">Log Out</a></p>
      </div>
    </div>
  </div>
        <h4> <strong>
            <span style="color: rgb(236, 228, 228)">
              Welcome  
              <h2>
                <span class="title-red">{{buyerName}}</span>
              </h2>
            </span>
          </strong>
        </h4> 

</ng-container>
