<app-common-loading></app-common-loading>
<main>
  <section class="adminpage">
    <div class="fullwrapper">
      <div class="adminpage-inner">
        <div id="tabs">
          <!-- Side Menu -->
          <ng-container *ngIf="isUserLogin">
            <app-nav-menu ></app-nav-menu>
          </ng-container>
          <!-- Side Menu END -->
          <!-- Haeder Menu --> 
        
            <app-header-menu></app-header-menu> 
 
          <!-- Haeder Menu END -->
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </section>
</main>
