import { Component } from '@angular/core';
import { AppSettings } from './model/menu'; 
import { Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
  isUserLogin:boolean = localStorage.getItem(AppSettings.authorize)? true:false;

  constructor(router:Router){
    // router.events.subscribe((res:any)=>{
    //   if(!localStorage.getItem(AppSettings.authorize) && res.url != "/login"){
    //     router.navigate(["login"]);
    //   }
    // })
  }
}
