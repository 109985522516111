export class Menu {
  public id: string;
  public icon: string;
  public labelName: string;
  public isActive: boolean = false;
  public subMenu: Array<Menu>;
  public routerUrl: string;
  public parentId: string;
  public permission: number;
}

export class AppSettings {
  public static baseURL: string;
  public static authorize: string;
  public static menu: string = 'menu';
  public static buyerID: string = 'buyerID';
}

export class CustomRouter {
  public url: string;
  public key: string;
  public redirect: boolean;
  public id: string;
  public data: any;
  public keepHistory: boolean;
}

export class Person {
  public id: string;
  public name: string;
  public password: string;
  public address: string;
  public token: string;
  public refreshToken: string;
  public email: string;
  public url: string;
  public buyerID: string;
}

export class PersonDetails {
  public id: string;
  public person: Person;
  public silentUrl: string[];
  public menu: Menu[];
}

export class SpeedexRouter {
  public url: string;
  public key: string;
  public redirect: boolean;
  public id: string;
  public data: any;
  public keepHistory: boolean;
}
