import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
   menu:any=null;
  constructor(private http:HttpClient) { 
  }

  fetchMenu(){
    if(!this.menu){
     return this.http.get('../assets/data/menu.json').pipe(map(res=>{
        return this.menu = res
      }))
    }else{
     return  of(this.menu)
    }
  }
}
