import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { AppSettings } from './app/model/menu';

function loadConfig() {
  return fetch('./assets/data/appSettings.json')
    .then((response) => response.json())
    .then((config) => {
      AppSettings.baseURL = config.baseUrl;
      AppSettings.authorize = config.authorize;
    });
}

loadConfig().then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
