import { Routes } from '@angular/router';
import { BuyerAuthGuardService } from '../guard/buyer-auth-guard.service';

const routes: Routes = [
    {
      path: '',
      canActivate:[BuyerAuthGuardService],
      loadChildren: () => import('../widgets/dash-board/dash-board.module').then(m => m.DashBoardModule)
    },
    {
      path: 'dashboard',
      canActivate:[BuyerAuthGuardService],
      loadChildren: () => import('../widgets/dash-board/dash-board.module').then(m => m.DashBoardModule)
    },
    {
        path: 'login',
        loadChildren: () => import('../common-widget/common-widget.module').then(m => m.CommonWidgetModule) 
      },
    {
        path: 'vehicles',
        canActivate:[BuyerAuthGuardService],
        loadChildren: () => import('../widgets/vehicle/vehicle.module').then(m => m.VehicleModule)
    },
    {
        path: 'maintance',
        canActivate:[BuyerAuthGuardService],
        loadChildren: () => import('../widgets/vehicle/vehicle.module').then(m => m.VehicleModule)
    },
    {
      path: 'deliveries',
      canActivate:[BuyerAuthGuardService],
      loadChildren: () => import('../widgets/deliveries/deliveries.module').then(m => m.DeliveriesModule)
    },
    {
      path: 'price',
      canActivate:[BuyerAuthGuardService],
      loadChildren: () => import('../widgets/price/price.module').then(m => m.PriceModule)
    },
    {
      path: 'fuel-report',
      canActivate:[BuyerAuthGuardService],
      loadChildren: () => import('../widgets/fuel-report/fuel-report.module').then(m => m.FuelReportModule)
    },
    {
      path: 'maintenance',
      canActivate:[BuyerAuthGuardService],
      loadChildren: () => import('../widgets/maintenance/maintenance.module').then(m => m.MaintenanceModule)
    }

];

export { routes }