<ng-container *ngIf="chartOptions">
    <apx-chart 
    [series]="chartOptions.series" 
    [chart]="chartOptions.chart" 
    [xaxis]="chartOptions.xaxis" 
    [dataLabels]="chartOptions.dataLabels" 
    [stroke]="chartOptions.stroke"
    [title]="chartOptions.title"
    [grid]="chartOptions.grid">
  </apx-chart>
  
</ng-container>
