import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApexChart } from 'ng-apexcharts';
import { of, switchMap } from 'rxjs';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnChanges {

  @Input() isVehicleDashBoard:boolean;
  data:any[];
  xAxis:Date[]=[];
  yAxis:number[]=[];
  yAxisDict:{[key:string]:number}={}
  chart:ApexChart;
  chartOptions:any;
  constructor(private datePipe:DatePipe,
    private decimalPipe: DecimalPipe,
    private vehicleService:VehicleService){
  }

  ngOnInit(){
    this.xAxis = [];
    this.setData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setData() 
  }

  setData(){
    this.vehicleService.getCachedAllVehicleHistory()
    .pipe(switchMap((res:any)=>{
      this.data = res;
      this.data.forEach(d=>{
        let date = this.datePipe.transform(new Date(d.createDate),"MMM dd,yyyy"); 
        let createDate = new Date(d.createDate);
        let year = createDate.getFullYear();
        let month = createDate.getMonth();
        let day = createDate.getDate(); 
        if(this.xAxis.length ==0 || this.xAxis.filter(v=>v.getFullYear() == year && v.getMonth() == month &&   v.getDate() == day).length < 1){
          this.xAxis.push(new Date(d.createDate))
        }
        if(!this.yAxisDict[date]){
          this.yAxisDict[date] =  d.price;
        }else{
          this.yAxisDict[date] = this.yAxisDict[date] + (d.price);
        }
      })
      Object.keys(this.yAxisDict).forEach(key => {
        this.yAxis.push(this.yAxisDict[key])
      })
      return of(this.yAxis)
    }))
    .subscribe((res:any)=>{
      //this.yAxis = [];
      res.forEach((v:any)=>{
        v = Number.parseFloat(this.decimalPipe.transform(v, '2.0-2'));
        //this.yAxis.push(v);
      })
      
      this.setChart() 
    })
  }


  setChart(){
    this.chart = {
      type: "line",
      zoom: {
        enabled: true,
        type: 'x',  
        autoScaleYaxis: false,  
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1
          }
        }
      }
    }

    this.chartOptions = {
      series: [{
        name: "Rate",
        data: this.yAxis
      }],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'  // You might want to change this for better visualization
      },
      title: {
        text: 'Product Trends by Month',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: this.xAxis.map(date => this.datePipe.transform(date, "MMM dd, yyyy")), // Convert Date to String
        title: {
          text: 'Date'
        }
      }
    };
  }

}
